@use "../global" as *;
/* footer */ 


.l-footer-realestate{
    background: url(../images/index/realestate_bg.png) no-repeat center / cover;
    height: 420px;
    text-align: center;
    position: relative;
    @include mq(md){
        height: 200px;
    }
    &__image{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 890px;
    }
}

.l-footer-contact{
    background: url(../images/index/contact_bg.png) no-repeat center / cover;
    
    // height:550px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 120px;
    box-sizing: border-box;
    @include mq(md){
        padding: 20px;
        box-sizing: border-box;
    }
    @include mq(md){
        flex-wrap: wrap;
    }

    &__contactArea{
        color: $color-white;
        
        &__ttl{
            font-size: 64px;
            font-weight: 700;
            margin-bottom: 10px;
            font-family: $centuly;
            @include mq(md){
                font-size:  2.5rem;
            }
        }
        &__smallTtl{
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 40px;
        }
        &__tel{
            font-size: 38px;
            font-weight: 700;
            @include mq(md){
                font-size:  2rem;
            }
        }
        &__fax{
            font-size: 38px;
            font-weight: 700;
            @include mq(md){
                font-size:  2rem;
            }
        }
    }
    &__formArea{
        margin-left: 300px;
        @include mq(md){
            margin: 30px auto;
        }
        &__formBtn{
            width: 500px;
            height: 100px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 30px;
            box-sizing: border-box;
            background: $color-orange;
            color: $color-white;
            text-decoration: none;
            @include mq(md){
                width: 100%;
                // padding: 0 10px;

            }
            @include hover();
            &__ja{
                font-size: 26px;
                font-weight: 700;
                @include mq(md){
                    font-size: 20px;
                    margin-right: 20px;

                }
            }
            &__en{
                font-size: 14px;
                font-weight: 700;

                position: relative;
                width: 110px;

                &::after{
                    content: "";
                    display: block;
                    background: $color-white;
                    width: 110px;
                    height: 1px;
                    position: absolute;
                }
                &::before{
                    content: "";
                    display: block;
                    background: $color-white;
                    width: 25px;
                    height: 1px;
                    right: -3px;
                    transform: rotate(45deg);
                    position: absolute;
                    top: 12px;
                    
                }
            }
        }
    }
}

.l-footer-company{
    font-weight: 700;
    padding: 60px 0px;
    box-sizing: border-box;
    @include mq(md){
        padding: 0 20px;
    }
    &__navList{
        display: flex;
        margin: 0 auto 60px;
        justify-content: center;
        list-style: none;
        @include mq(md){
            display: block;
            margin: 40px auto;
        }
        &__item{
            padding: 0 30px;
            box-sizing: border-box;
            text-decoration: none;
            color: $color-black;
            @include mq(md){
                display: block;
                margin: 0 auto;
                text-align: center;
                font-size: 1.125rem;
                line-height: 40px;
            }
        }
    }
    &__info{
        text-align: center;
        &__logo{
            width: 160px;
            margin-bottom: 40px;
        }
        &__companyName{
            font-size: 20px;
            margin-bottom: 10px;
            @include mq(md){
                font-size: 1rem;
            }
        }
        &__address{
            font-size: 20px;
            margin-bottom: 10px;
            font-weight: 500;
            @include mq(md){
                font-size: 1rem;
            }
        }
        &__contact{
            font-size: 20px;
            margin-bottom: 10px;
            font-weight: 500;
            @include mq(md){
                font-size: 1rem;
            }
        }
    }

}

.l-footer-copy{
    font-size: 14px;
    margin: 20px 30px;
    text-align: left;
    font-weight: 500;
}