@use "../../global" as *;

.c-card{
    &-property{
        list-style: none;
        // padding-right: 30px;
        width: 580px !important;
        &--lower{
            margin-bottom: 80px;
            @include mq(md){
                width: 100% !important;
                margin: 0 auto 30px;
            }
        }
        @include mq(md){
            width: 300px !important;

        }
        &Inner{
            width: 580px;
            position: relative;
            @include mq(md){
                width: 300px !important;
            }
            &__img{
                width: 100%;
                height: 435px;
                object-fit: cover;
                @include mq(md){
                    height: 300px;
                }
            }
            &__category{
                line-height: 40px;
                min-width: 100px;
                text-align: left;
                width: fit-content;
                position: absolute;
                background: $color-navy;
                left: 0;
                top: 0;
                color: $color-white;
                padding-left: 10px;
                box-sizing: border-box;
            }
            &__txtArea{
                background: $color-gray-01;
                padding: 30px 20px;
                box-sizing: border-box;
                margin-top: -10px;
                &__name{
                    font-size: 1.25rem;
                    font-weight: 700;
                }
                &__area{
                    color: $color-gray-02;
                    display: flex;
                    align-items: center;
                    img{
                        width: 15px;
                    }
                }
            }
        }
    }
}