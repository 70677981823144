/* About */
@use "../../global" as *;

.p-property{
    &-container{
        max-width: 1240px;
        margin: 60px auto 165px;
        @include mq(md){
            width: 100%;
            padding: 40px 20px;
            box-sizing: border-box;
            margin: 0px auto;
        }
    }
    &-list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    
}