// ブレイクポイント
$breakpoints: (
    "sm": "screen and (max-width: 400px)",
    "md": "screen and (max-width: 768px)",
    "lg": "screen and (max-width: 1000px)",
    "xl": "screen and (max-width: 1200px)",
    "xxl": "screen and (min-width: 1400px)",
) !default;

// メディアクエリー
@mixin mq($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
      @content;
  }
}