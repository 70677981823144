@use "../global" as *;
/* header */ 

.l-header{
    &-wrapper{
        position: fixed;
        top: 0;
        left: 0;
        z-index:  10000;
        width: 100%;
        transition: 0.5s;
        &--bg-white{
            background: $color-white;
            // transition: 0.5s;
            .nav_item{
                color: $color-black !important;
            }
        }
    }

    &-inner{
        width: 100%;
        height: 90px;
        align-items: center;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        color: $color-white;
        &--lower{
            background: $color-white;
            color: $color-black;
        }
        @include mq(md){
            align-items: flex-start;
            background: $color-white;
            height: 60px;
        }
        &__logo{
            max-width: 170px;
            height: 92px;
            @include hover();
            @include mq(md){
                width: 100px;
            }
        }
        &__nav{
            &List{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                list-style: none;
                &__item{
                    height: 90px;
                    box-sizing: border-box;
                    color: $color-white;
                    font-weight: 700;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 20px;
                    @include hover();
                    &--lower{
                        color: $color-black;
                    }
                    &--contact{
                        background: $color-orange;
                        color: $color-white;
                        width: 180px;
                        margin-left: 50px;
                        @include hover();
                    }
                }
            }
            
        }
    }
    &-nav{
        &Item{
            padding: 20px 0;
            border-bottom: 1px solid $color-gray-01;
            &:nth-child(5){
                border-bottom: none;
            }
            &:nth-child(6){
                border-bottom: none;
            }
            a{
                color: $color-white;
                font-size: 1.25rem;
                text-decoration: none;
                line-height: 30px;
                text-align: center;
                font-weight: 700;

            }
        }
        &__sp{
            display: none;
            @include mq(md){
                display: block;
            }   
            &__inner{
                width: 100vw;
                position: relative;
                height: 60px;
                width: 60px;
                background: $color-navy;
            }
            &__btn{

                #sp_btn{
                    width: 40px;
                    height: 4px;
                    background: $color-white;
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: translate(0, -50%);
                    &::after{
                        display: block;
                        content: "";
                        width: 40px;
                        height: 4px;
                        position: absolute;
                        background: $color-white;
                        top: 12px;
                    }
                    &::before{
                        display: block;
                        content: "";
                        width: 40px;
                        height: 4px;
                        position: absolute;
                        background: $color-white;
                        top: -12px;
                    }
                }
            }

            &__list{
                width: 100vw;
                height: 100vh;
                position: absolute;
                top: 60px;
                right: 0;
                padding: 0px;
                list-style: none;

                &.close{
                    display: none;
                    right: -100vw;
                    
                }
                &.open{

                    display: block;
                    background: $color-navy;
                    right: 0;
                    
                }
            }
        }
    }
    &-contactBtn{
        background: $color-white;
        display: block;
        line-height: 50px !important;
        width: 300px;
        margin: 0px auto;
        color: $color-navy !important;

    }
}