@use "../../global/" as *;

.pc_only{
    display: initial;
    @include mq("md"){
        display: none;
    }
}

.sp_only{
    display: none;
    @include mq("md"){
        display: initial;
    }
}