@use "../../global" as *;

.u-fonts{
    &-en{
        font-family: $centuly;
    }
    &-size{
        &14{
            font-size: 0.875rem;
        }
        &16{
            font-size: 1rem;
        }
        &22{
            font-size: 1.375rem;
        }
        &32{
            font-size: 2rem;
            @include mq(md){
                font-size: 1.25rem;
            }
        }
        &46{
            font-size: 2.875rem;
        }
    }
}