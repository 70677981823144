/* About */
@use "../../global" as *;

.p-news{
    &-container{
        width: 900px;
        margin: 60px auto 165px;
        @include mq(md){
            width: 100%;
            padding: 40px 20px;
            box-sizing: border-box;
            margin: 0px auto;
        }
            // single
        &__ttl{
            font-size: 2rem;
            @include mq(md){
                font-size: 1.5rem;
            }
        }
        &__detail{
            margin: 40px auto;
        }
    }

}