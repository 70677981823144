/* home */
@use "../../global" as *;

.p-home-mv{
    &__inner{
        position: relative;
        height: 1080px;
        margin-bottom: 140px;
        @include mq(md){
            height: 500px;
            margin-bottom: 40px;
        }
        &__logo{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 200px;
        }
    }
}


.p-home-business{
    @include mq(md){
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 40px;
    }
    margin-bottom: 100px;
    &__btn{
        width: 840px;
        margin-top: 30px;
        display: flex;
        @include mq(md){
            display: block;
            width: 100%;
        }

    }
    &__copy{
        text-align: center;
        font-size: 30px;
        line-height: 50px;
        margin-bottom: 140px;
        font-weight: 700;
        @include mq(md){
            font-size: 20px;
            text-align: left;
            margin-bottom: 40px;

        }
    }
    &-list{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
        max-width: 960px;
        margin: 0 auto;
        @include mq(md){
            grid-template-columns: 1fr;
            gap: 30px;
        }

        &__item{
            position: relative;
            overflow: hidden;
            cursor: pointer;
            &:hover{
                img{
                    transform: scale(1.1);
                    transition: 0.5s;
                    @include mq(md){
                        transform: none;
                        transition: none;
                    }
                }   
            }
            &:hover > &__detail{
                display: block;
                opacity: 1.0;
                transition: 1s;
                
            }
            &__number{
                top: 20px;
                left: 20px;
                position: absolute;
                font-size: 38px;
                color: $color-white;
                display: flex;
                align-items: flex-start;
                font-family: $centuly;
                &--small{
                    font-size: 14px;
                    padding-top: 10px;
                }
            }
            &__ttl{
                top: 43%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                position: absolute;
                font-size: 2rem;
                font-weight: 700;
                color: $color-white;
                max-width: 100%;
                width: 100%;
                @include mq(md){
                    font-size:  1.25rem;
                    width: 100%;
                }

                &--small{
                    font-size: 14px;
                    font-weight: 700;
                    font-family: $centuly;
                }
            }
            &__detail{
                max-width: 120px;
                text-align: left;
                bottom: 20px;
                right: 100px;
                position: absolute;
                font-size: 14px;
                color: $color-white;
                opacity: 0;
                padding-bottom: 5px;
                @include mq(md){
                    opacity: 1.0;
                    right: 90px;
                }
                &::after{
                    content: "";
                    display: block;
                    background: $color-white;
                    width: 120px;
                    height: 1px;
                    position: absolute;
                    
                }
                &::before{
                    content: "";
                    display: block;
                    background: $color-white;
                    width: 25px;
                    height: 1px;
                    right: -74px;
                    transform: rotate(45deg);
                    position: absolute;
                    top: 12px;
                    
                }
                
            }
        }

    
    }
}

.p-home-property{
    margin-bottom: 100px;
    &__inner{
        margin-bottom: 80px;
    }
}


.p-home-strength{
    position: relative;
    height: 960px;
    @include mq(xl){
        height: 840px;
    }
    &Inner{
        z-index: 100;
        position: relative;
        padding-top: calc(( 960px - 690px ) / 2);
        display: flex;
        justify-content: flex-end;
        @include mq(xl){
            display: block;
            padding: 20px;
            box-sizing: border-box;
        }
        
        &::after{
            content: "";
            display: block;
            background: url(../images/index/strength_bg.png) no-repeat center / cover;
            width: 70vw;
            height: 960px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            @include mq(xl){
                width: 100%;
                display: none;
            }
        }
        &__ttlArea{
            position: relative;
            z-index: 100;
            margin-left: 20vw;
            @include mq(xl){
                margin: 0 auto;
            }
        }
        &__list{
            position: relative;
            z-index: 100;
            border-left: 1px solid $color-navy;
            padding-left: 65px;
            margin-left: 65px;
            @include mq(xl){
                // padding: 20px;
                margin: 0 auto;
                padding-left: 0px;
                border: none;
            }
            
            &Item{
                display: flex;
                width: 54vw;
                height: 210px;
                margin-bottom: 30px;
                align-items: center;
                padding: 25px 0px;
                box-sizing: border-box;
                @include mq(xl){
                    width: 100%;
                    height:  200px;
                    display: block;
                    text-align: center;
                    
                }

                // background: url(../images/index/strength01.png);
                &:nth-child(1){
                    background: url(../images/index/strength01.png) no-repeat center / cover;
                    @include mq(xl){
                        background: url(../images/index/strength01.png) no-repeat center / cover;
                    }
                }
                &:nth-child(2){
                    background: url(../images/index/strength02.png) no-repeat center / cover;
                    @include mq(xl){
                        background: url(../images/index/strength02.png) no-repeat center / cover;
                    }
                }
                &:nth-child(3){
                    background: url(../images/index/strength03.png) no-repeat center / cover;
                    @include mq(xl){
                        background: url(../images/index/strength03.png) no-repeat center / cover;
                    }
                    margin-bottom: 0px;
                }
                &__ttlArea{
                    font-family: $centuly;
                    text-align: center;
                    padding: 30px 50px;
                    border-right: 1px dotted $color-white;
                    font-weight: 700;
                    // height: 155px;
                    @include mq(xl){
                        padding: 10px;
                        border-right: none;
                    }
                    
                    &__ttl{
                        color: $color-white;
                        font-size: 0.875rem;
                        // padding: 10px ;
                        box-sizing: border-box;
                    }
                }
                &__detail{
                    font-size: 2rem;
                    padding-left: 40px;
                    color: $color-white;
                    font-weight: 700;
                    @include mq(xl){
                        font-size: 1.125rem;
                        padding-left: 0px;
                    }
                }
                
            }
        }
        &__ttlArea{
            &__ttl{

                &__en{
                    font-size: 4rem;
                    color: $color-navy;
                    font-weight: 700;
                    font-family: $centuly;
                    @include mq(xl){
                        font-size: 2.5rem;
                        text-align: center;
                    }
                }
                &__ja{
                    font-size: 1.375rem;
                    color: $color-navy;
                    font-weight: 700;
                    @include mq(xl){
                        text-align: center;
                        margin-bottom: 30px;
                    }
                }
            }
        }
        
    }
}

.p-home-property{
    &__inner{
        &__list{
            display: flex;
        }
    }
}


.p-home-news{
    &Inner{
        background: url(../images/index/news_bg.png) center / cover;
        padding: 0px 100px 100px;
        max-width: 1240px;
        margin: 0 auto 100px;
        @include mq(md){
            padding: 40px 20px;
            box-sizing: border-box;
        }
    }
}

