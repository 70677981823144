@use "../../global" as *;

.c-news{
    &__list{
        margin-bottom: 80px;
        list-style: none;
        &Item{
            margin-bottom: 30px;
            a{
                text-decoration: none;
                color: $color-black;
            }
            &Inner{
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                max-width: 750px;
                margin: 0 auto;
                @include mq(md){
                    display: block;
                }
                &__date{
                    margin-right: 40px;
                }   
                &__ttl{
                    font-size: 1.125rem;
                    font-weight: 700;
                }
            }

        }
    }
}