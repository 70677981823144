@use "../../global" as *;
/* component btn */ 

.c-title-container{
    padding: 120px 0px 90px;
    text-align: center;
    @include mq(md){
        padding: 40px 0px;
        box-sizing: border-box;
    }
    &__ja{
        font-size: 4rem;
        font-weight: 900;
        color: $color-navy;
        font-family: $centuly;
        letter-spacing: 0.15em;
        @include mq(md){
            font-size: 2.5rem;
        }
    }
    &__en{
        font-size: 1.375rem;
        font-weight: 700;

        color: $color-navy;
    }
}