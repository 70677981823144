@use "../../global" as *;

.l-lower{
    &-mv{
        height: 500px;
        position: relative;
        margin-top: 90px;
        @include mq(md){
            height: 200px;
            margin-top: 60px;
        }
        
        &--news{
            background: url(../images/news/bg.png) no-repeat center / cover ;
        }
        &--about{
            background: url(../images/about/bg.png) no-repeat center / cover ;
        }
        &--contact{
            background: url(../images/contact/bg.png) no-repeat center / cover ;
        }
        &--property{
            background: url(../images/property/bg.png) no-repeat center / cover ;
        }
        &Inner{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            &__ttl{
                font-size: 4rem;
                text-align: center;
                color: $color-white;
                @include mq(md){
                    font-size: 2rem;
                }

                &--ja{
                    font-size: 1.625rem;
                    font-weight: 700;
                    @include mq(md){
                        font-size: 1.25rem;
                    }
                }
                &--en{
                    font-family: $centuly;
                }
            }
        }
    }
    &-breadcrumb{
        max-width: 1200px;
        margin: 0 auto;
        @include mq(md){
            padding: 0 10px;
        }   
        &List{
            display: flex;
            justify-content: flex-start;
            padding: 20px 0px;
            list-style: none;
            &__item{
                padding: 0 10px;
                font-weight: 700;
                font-size: 0.875rem;

                a{
                    text-decoration: none;
                    color: $color-black;
                }
            }
        }
    }
}