$color-orange: #dc6414;
$color-white: #ffffff;
$color-black: #000000;
$color-navy: #002d64;
$color-gray-01: #e3e3e3;
$color-gray-02: #808080;
$color-gray-03: #8a8a8a;
$color-gray-04: #f1f1f1;
$color-gray-05: #e5e5e5;

