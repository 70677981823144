@use "../../global" as *;
/* component btn */ 


.c-btn-more{
    width: 480px;
    padding: 25px;
    box-sizing: border-box;
    text-align: left;
    font-size: 1.375rem;
    display: flex;
    justify-content: space-between;
    background: $color-navy;
    color: $color-white;
    font-weight: 700;
    margin: 0 auto;
    text-decoration: none;
    align-items: center;
    @include mq(md){
        width: 90%;
    }
    &__detail{
        // padding-right: 40px;
        position: relative;
        @include mq(md){
            width: 100px;
            display: block;
        }
        &::after{
            content: "";
            display: block;
            width:  120px;
            height: 1px;
            background: $color-white;
            @include mq(md){
                width: 100%;
            }
            
        }
        &::before{
            content: "";
            display: block;
            width:  20px;
            height: 1px;
            right: -3px;
            bottom: 7px;
            background: $color-white;
            transform: rotate(45deg);
            position: absolute;
        }
    }
    
    &:hover{
        opacity: 0.7;
        transition: 0.5s ease;
    }
}