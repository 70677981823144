@use "../../global" as *;

.p-contact{
    &-wrapper{
        width: 1000px;
        margin: 0 auto;
        @include mq(md){
            width: 100%;
        }
    }
    &-headline{
        padding: 50px 0px;
        text-align: center;
        font-weight: 700;
        @include mq(md){
            padding: 50px 20px;
            text-align: left;
        }
    }
    &-table{
        @include mq(md){
            width: 100%;
        }
        th{
            display: flex;
            padding: 20px 40px;
            justify-content: space-between;
            box-sizing: border-box;
            font-size: 1.125rem;
            align-items: center;
            width: 300px;
            position: relative;
            @include mq(md){
                width: 100%;
            }
            
            span{
                background: $color-orange;
                padding: 5px 10px;
                color: $color-white;
                line-height: 26px;
                width: 50px;
                font-size: 0.825rem;
            }

            &.zip{

                &::after{
                    content: "〒";
                    display: block;
                    position: absolute;
                    right: 6px;
                    top: 50%;
                    transform: translate(0, -50%);
                    @include mq(md){
                        display: none;
                    }
                }

            }
        }
        td{
            @include mq(md){
                display: block;
            }

        }

        
    }
    &-inputField{
        padding: 10px;
        input{
            width: 680px;
            background: $color-gray-04;
            border: 1px solid $color-gray-05;
            line-height: 50px;
            box-sizing: border-box;
            padding-left: 10px;
            @include mq(md){
                width: 100%;
            }
            &.zipcode{
                width: 250px;
                @include mq(md){
                    width: 100%;
                }

            }
        }
        textarea{
            width: 680px;
            background: $color-gray-04;
            border: 1px solid $color-gray-05;
            box-sizing: border-box;
            padding: 10px;
            @include mq(md){
                width: 100%;
            }
        }
    }
    &-privacy{
        text-align: center;
        margin: 20px auto;
        @include mq(md){
            text-align: left;
            padding: 20px;
        }
    }
    &-agree{
        display: flex;
        justify-content: center;

    }
    &-btn{
        width: 500px;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        box-sizing: border-box;
        background: $color-orange;
        color: $color-white;
        text-decoration: none;
        margin: 80px auto;
        position: relative;
        @include mq(md){
            width: 85%;
            padding: 10px 20px;
            // padding: 0 10px;
            margin: 40px auto;
        }
        &::after{
            content: "";
            display: block;
            background: $color-white;
            width: 110px;
            height: 1px;
            position: absolute;
            right: 35px;
            top: 50%;
            transform: translate(0%, 10px);
        }
        &::before{
            content: "";
            display: block;
            background: $color-white;
            width: 25px;
            height: 1px;
            right: 31px;
            transform: rotate(45deg);
            position: absolute;
            top: 50%;
            
        }
        @include hover();
        &__ja{
            font-size: 26px;
            font-weight: 700;
            @include mq(md){
                font-size: 20px;
                margin-right: 20px;

            }
        }
        &__en{
            font-size: 14px;
            font-weight: 700;

            position: relative;
            width: 110px;


        }
    }
}