/* About */
@use "../../global" as *;


.p-about-business{
    &-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-bottom: 80px;
        @include mq(md){
            flex-wrap: wrap;
            width: 100%;
            &--reverse{
                flex-direction: column-reverse;
            }
        }

    }
    &-txt{
        width: fit-content;
        padding-left: 15vw;
        @include mq(md){
            padding: 20px;
            box-sizing: border-box;
            width: 100%;
        }
        &--right{
            padding-left: 10vw;
            // padding-left:vw;
            width: 100%;
            @include mq(md){
                padding: 20px;
                box-sizing: border-box;
            }
        }
        &__number{
            font-size: 3.125rem;
            color: $color-navy;
            font-weight: 900;
            display: flex;
            align-items: flex-start;
            font-family: $centuly;
            font-weight: 900;
        }
        &__en{
            font-size: 3.5rem;
            color: $color-navy;
            font-family: $centuly;
            font-weight: 900;
            @include mq(md){
                font-size: 2.5rem;
            }
        }
        &__ja{
            font-size: 2.25rem;
            position: relative;
            font-weight: 900;
            margin-bottom: 40px;

            &::after{
                content: "";
                display: block;
                height: 2px;
                width: 110px;
                background: $color-orange;
                position: absolute;
                bottom: -10px;
            }
            @include mq(md){
                font-size: 1.5rem;
            }
        }
        &__detail{
            font-size: 1.125rem;
            line-height: 1.5;
            max-width: 560px;
        }
    }
    &-img{
        width: 50%;
        height: 520px;
        object-fit: cover;
        @include mq(md){
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            height: 300px;
        }
    }
}

.p-about-company{
    &-wrapper{
        background:  url(../images/about/about_bg.png) no-repeat center / cover;
        padding-bottom: 60px;
        margin-top: 80px;
    }
    &-container{
        width: 1240px;
        padding: 40px 0px;
        box-sizing: border-box;
        margin: 0 auto;
        @include mq(md){
           width: 100%;
            padding: 20px;
             box-sizing: border-box;
        }
    }
    &__ttl{
        font-size: 2.5rem;
        padding: 10px 40px;
        box-sizing: border-box;
        color: $color-navy;
        border-top: 2px solid $color-navy;
        border-bottom: 2px solid $color-navy;
        display: flex;
        align-items: flex-end;
        margin-bottom: 30px;
        // font-family: $centuly;
        @include mq(md){
            font-size: 2rem;
            .u-fonts-size22{
                font-size: 1rem;
            }
        }
        .u-fonts-en{
            margin-right: 10px;
        }

    }
    &__message{
        padding: 40px;
        box-sizing: border-box;
        @include mq(md){
            padding: 0px;
        }
    }
    &__name{
        text-align: right;

    }
    &__table{
        border-collapse: collapse;
        width: 1180px;
        margin: 0 auto;
        @include mq(md){
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
        }
        tr{
            border-bottom: 1px solid $color-gray-03;
        
            &:last-child{
                border-bottom: none;
            }
        }
        th{
            padding:  20px;
            box-sizing: border-box;
            text-align: right;
            vertical-align: initial;
            border-right: 1px solid $color-gray-03;
            @include mq(md){
                display: block;
                text-align: left;
                padding: 10px 0px;
                border-right: none;
            }
        }
        td{
            padding:  20px;
            box-sizing: border-box;
            text-align: left;
            vertical-align: initial;
            @include mq(md){
                display: block;
                text-align: left;
                padding: 10px 0px;
            }
        }
    }
}

.p-about-map{
    &-container{
        display: flex;
        justify-content: space-between;
        padding: 120px 0px;
        @include mq(md){
            flex-wrap: wrap;
        }
    }
    &__img{
        width: 40vw;
        @include mq(md){
            width: 100%;
        }
    }
    &__iframe{
        width: 60vw;
        padding-left: 20px;
        @include mq(md){
            width: 100%;
            padding-left: 0px;
        }
    }
}